.c-player {
  display: block;
  /* background-color: rgb(19, 18, 18); */
  background-color: #686868;
  padding: 25px;
  border-radius: 18px;
  box-shadow: inset -6px -6px 12px rgba(0, 0, 0, 0.8),
    inset 6px 6px 12px rgba(161, 161, 161, 0.4);
}
.c-player > h4 {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  /* text-align: center; */
}

.c-player > p {
  color: #aaa;
  font-size: 14px;
  /* text-align: center; */
  font-weight: 600;
}

.c-player > p span {
  font-weight: 400;
}
.c-player--details .details-img {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.c-player--details .details-img img {
  display: block;
  margin: 50px auto;
  width: 100%;
  max-width: 250px;
  border-radius: 50%;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.8),
    -6px -6px 12px rgba(255, 255, 255, 0.4);
}
.c-player--details .details-img:after {
  content: "";
  display: block;
  position: absolute;
  top: -25px;
  left: -25px;
  right: -25px;
  bottom: -25px;
  border-radius: 50%;
  border: 3px dashed rgb(212, 0, 255);
}

.c-player--details .details-title {
  color: #eee;
  font-size: 28px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
  /* text-align: center; */
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.c-player--details .details-artist {
  color: #aaa;
  font-size: 20px;
  margin-top: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
  /* text-align: center; */
  /* margin-bottom: 20px; */
}
.c-player--controls {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* left: 10; */
  /* margin-right: 40px; */
  margin-bottom: 30px;
}

.c-player--controls .play-btn {
  display: flex;
  margin: 0 30px;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8),
    -4px -4px 10px rgba(255, 255, 255, 0.4),
    inset -4px -4px 10px rgba(0, 0, 0, 0.4),
    inset 4px 4px 10px rgba(255, 255, 255, 0.4) !important;
  border: none;
  outline: none;
  background-color: rgb(212, 0, 255);
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}
.c-player--controls .skip-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: #888;
  font-size: 19px;
}
@media screen and (min-width: 768px) {
  .c-player--controls {
  margin-right: 50px ;
  margin-top: 5px;

  }
  .heart-change{
    position: relative;
    left: 30%;
  }
}